import React from 'react'

// Cette section est placée à la fin de la page pour résoudre un bug dans la barre de navigation
function Fill () {
  return (
    <div id="fill">

    </div>
  )
}

export default Fill
