import React, { useEffect } from "react";
import Navbar from './components/Navbar';
import Accueil from './components/Accueil';
import APropos from './components/APropos';
import SectionFonc from './components/SectionFonc';
import SectionTelechargement from './components/SectionTelechargement';
import SectionDemo from './components/SectionDemo';
import Tarification from './components/Tarification';
import Footer from './components/Footer';
import Fill from './components/Fill';
import { ParallaxProvider } from 'react-scroll-parallax';

import ReactGA from "react-ga4";

const MEASUREMENT_ID = "G-9R37WT9PSM" //Measurement ID Google Analytics
ReactGA.initialize(MEASUREMENT_ID)

//Nombre de visites
//ReactGA.pageview(document.location.pathname)
ReactGA.send({ hitType: "pageview", page: "/"});

function App(){

  function initializeReactGA() {
    window.gtag('config', 'G-9R37WT9PSM');
    ReactGA.initialize('G-9R37WT9PSM');
  }

  //Visite sur le site et initialisation de ReactGA
  useEffect(() => {
    initializeReactGA();
  }, [])

  return (
      <>
         <Navbar/>  
          <div className = "container">
            <ParallaxProvider> 
              <Accueil/>
              <APropos/>
              <SectionFonc/>
              <SectionTelechargement/>  
              <SectionDemo/>  
              <Tarification/>
            </ParallaxProvider>
          <Footer/>
          <Fill/>
          </div> 
      </>
    );
}

export default App;
