import React, { useEffect, useState, useRef }  from 'react'
import ClientData from "./data/ClientData";
import Slider from "react-slick";
import '../style/SectionDemo.scss';
import '../style/VideoDemo.scss';
import "../style/Clients.scss";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import useAnalyticsEventTracker from './analytics/useAnalyticsEventTracker';

function SectionDemo() {

  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoTimeWatched, setVideoTimeWatched] = useState(0);
  const [hasEventTriggered, setHasEventTriggered] = useState(false); 
  const videoLength = 120; // 2 minutes in seconds

  // Video +2 minutes
  useEffect(() => {
    const handleVideoPlay = () => {
      setIsVideoPlaying(true);
    };
    
    const handleVideoTimeUpdate = () => {
      setVideoTimeWatched(Math.round(videoRef.current.currentTime));

      if (videoTimeWatched >= videoLength && !hasEventTriggered) {
        setHasEventTriggered(true);
        gaEventTracker("Le nombre de fois où la vidéo de démonstration a été visionnée +2 minutes")
      }
    };
    
    if (videoRef.current) {
      videoRef.current.addEventListener('play', handleVideoPlay);
      videoRef.current.addEventListener('timeupdate', handleVideoTimeUpdate);
    }
    
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('play', handleVideoPlay);
        videoRef.current.removeEventListener('timeupdate', handleVideoTimeUpdate);
      }
    };
  }, [hasEventTriggered, videoTimeWatched]);

  const gaEventTracker = useAnalyticsEventTracker('Video +2 minutes');

  //Slider clients
  const settings = {
    infinite: true,
    centerMode:true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div id="demo">
      <h1 className="title titleDemo">
          Démo
      </h1>

      <div className="sm-container">
          <p className="sm-paragraph">
            Ci-dessous, une présentation succincte de GEROBA et de quelques fonctionnalités.
          </p>
      </div>
      
      {/* Video demonstration */}
      <div className="video-container">
        
        {/*Video*/} 
        <video ref={videoRef} className="videoDemo" width="640" height="264" controls loop muted autoPlay data-video-length={videoLength}>
          <source src="/VidéoComGERAOBA-v2.mp4" type="video/mp4"/>
        </video> 


      </div>
      
      {/*Slider collaborations*/}
      <p className="pclients"><i>Ils nous font confiance... Voici quelques exemples de collaborations qui illustrent notre savoir-faire.</i></p>
      <div className="cards">
        <Slider {...settings}>
          {ClientData.map((item, index) => (
          <div key="item" className="card"> 
            <img className="clientLogo" id={index} src={item.imgPath} alt={item.title} title={item.title}/>
          </div>
          ))}
        </Slider>     
      </div>
    </div>
  )
}

export default SectionDemo;